import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from 'App/auth/reducer'
import content from 'App/content/reducer'
import user from 'App/user/reducer'
import videos from 'App/videos/reducer'
import focus from 'App/focus/reducer'
import show from 'components/Show/reducer'
import player from 'components/Player/reducer'
import modal from 'components/Modal/reducer'
import redirect from 'App/redirects/reducer'

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    content,
    user,
    videos,
    show,
    focus,
    player,
    modal,
    redirect
  })
