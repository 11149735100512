import React from 'react'
import { connect } from 'react-redux'
import { playAndPause, fastForward, rewind, destroyPlayer } from './actions'
import { usedKey } from 'App/focus/actions'
import Controls from './Controls'
import { StyledDiv } from './Player.style'

const PlayerControls = ({
  player,
  focus,
  playAndPause,
  fastForward,
  rewind,
  destroyPlayer,
  usedKey
}) =>
  !!player && (
    <StyledDiv id={'player-controls'}>
      <Controls
        {...{
          player,
          focus,
          playAndPause,
          fastForward,
          rewind,
          destroyPlayer,
          usedKey
        }}
      />
    </StyledDiv>
  )

const mapStateToProps = ({ player, focus }) => ({ player, focus })
const connected = connect(
  mapStateToProps,
  { playAndPause, fastForward, rewind, destroyPlayer, usedKey }
)(PlayerControls)
export default connected
