export const VIRGIN_HORIZON = {
  DEV_KIDS_PACK_ENTITLED: '2038',
  LIVE_KIDS_PACK: '2038',
  TV_CHANNEL_UPGRADES_URL: 'com.libertyglobal.app.tvChannelUpgradesUk'
}

export const VIRGIN_TIVO = {
  LIVE_KIDS_PACK: 2038,
  TV_CHANNEL_UPGRADES_URL:
    'x-tivo:web:sandbox_app:https://content.dtv.virginmedia.com/htmlApps/tvChannelUpgrades/index.html'
}

export const STANDALONE_PLAYER_BASE_URL = {
  DEVELOPMENT: 'https://htmltvplayer-dot-hopster-dev.appspot.com/',
  TESTING: 'https://htmltvplayer-dot-hopster-test.appspot.com/',
  STAGING: 'https://htmltvplayer-dot-hopster-staging.appspot.com/',
  PRODUCTION: 'https://htmltvplayer-dot-hopster-service.appspot.com/'
}

export const ENVIRONMENT = {
  DEVELOPMENT: 'hopster-dev',
  TESTING: 'hopster-test',
  STAGING: 'hopster-staging'
}
