import videojs from 'video.js'
import store from 'store'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import {
  getBrightcoveAuthHeaders,
  downloadHls,
  getStandalonePlayerBaseUrl
} from 'utils'

export const CREATE_PLAYER = 'CREATE_PLAYER'
export const PLAYER_READY = 'PLAYER_READY'
export const PLAY_AND_PAUSE = 'PLAY_AND_PAUSE'
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS'
export const DESTROY_PLAYER = 'DESTROY_PLAYER'
export const ADD_TO_LAST_WATCHED = 'ADD_TO_LAST_WATCHED'
export const SPOOLING_PLAYER = 'SPOOLING_PLAYER'
export const SEEK_PLAYER = 'SEEK_PLAYER'
export const PAUSED = 'PAUSED'
export const SET_PLAYER_ACTION_TYPE = 'SET_PLAYER_ACTION_TYPE'

let duration
let i = 0
let videoJSPlayer, hls, Hls
let video = document.getElementById('video')
let playerContainer = document.getElementById('player')
const { REACT_APP_BUILD_TYPE } = process.env

export function createPlayer(item) {
  return dispatch => {
    dispatch(createPlayerRequest())
    if (
      REACT_APP_BUILD_TYPE !== 'vodafonero' &&
      REACT_APP_BUILD_TYPE !== 'gibtelcom'
    ) {
      downloadHls()
        .then(module => (Hls = module))
        .catch(err => console.log(err))
    }
    axios
      .get(
        `https://edge.api.brightcove.com/playback/v1/accounts/6057949418001/videos/${item.brightcove_id}`,
        getBrightcoveAuthHeaders()
      )
      .then(res => {
        const sources = []
        playerContainer.classList.add('display')
        duration = res.data.duration
        if (
          REACT_APP_BUILD_TYPE === 'vodafonero' ||
          REACT_APP_BUILD_TYPE === 'gibtelcom'
        ) {
          res.data.sources.map(
            s => s.type === 'application/x-mpegURL' && sources.push(s.src)
          )
          createVideoJSPlayer(dispatch, sources)
        } else if (REACT_APP_BUILD_TYPE === 'virgin') {
          res.data.sources.map(source => {
            var s = document.createElement('source')
            s.src = source.src
            if (
              !!source.type &&
              s.src.includes('https') &&
              s.src.includes('.mpd')
            ) {
              s.type = source.type
              window.location.href = `${getStandalonePlayerBaseUrl()}?v=${encodeURI(
                s.src.split('?')[0]
              )}&fastly_token=${encodeURI(
                s.src.split('?')[1].split('fastly_token=')[1]
              )}&step=true`
            }
          })
        } else {
          res.data.sources.map(
            s => s.type === 'application/x-mpegURL' && sources.push(s.src)
          )
          createHlsPlayer(dispatch, sources)
        }
      })
  }
}

const createVideoJSPlayer = (dispatch, sources) => {
  if (!document.getElementById('video')) {
    video = document.createElement('video')
    video.id = 'video'
    video.className = 'video-js'
    playerContainer.appendChild(video)
    video = document.getElementById('video')
  }
  video.className = 'video-js'
  videoJSPlayer = videojs(video, {
    controls: false,
    preload: 'auto',
    textTrackSettings: false,
    responsive: true,
    autoplay: true
  })
  videoJSPlayer.src(sources[1])

  videoJSPlayer.on('ready', () => {
    videojs.log('Your player is ready!')
    dispatch(createPlayerReceived(video))
  })
  videoJSPlayer.on('ended', () => {
    videojs.log('Your player will be dispose!')
    dispatch(destroyPlayer())
  })
}

const createHlsPlayer = (dispatch, sources) => {
  hls = new Hls({
    startLevel: 0,
    capLevelToPlayerSize: true,
    capLevelOnFPSDrop: true,
    maxBufferSize: 1000
  })
  hls.attachMedia(video)
  // TODO: change this back to increase quality for platforms other than virgin
  hls.autoLevelCapping = 0
  video.onpause = () => dispatch(paused())
  video.controls = false
  hls.on(Hls.Events.MEDIA_ATTACHED, function() {
    // HACK: Requires &.m3u8 appended to video URLs as video's won't stream at all on Gibtelecom, Netgem, EETV.
    if (REACT_APP_BUILD_TYPE === 'gibtelcom') {
      hls.loadSource(`${sources[1]}&.m3u8`)
    } else {
      hls.loadSource(sources[1])
    }
    hls.on(Hls.Events.MANIFEST_PARSED, function(event, data) {
      console.log(
        'manifest loaded, found ' + data.levels.length + ' quality level'
      )
      dispatch(createPlayerReceived(video))
      dispatch(playAndPause(video, false, 0))
    })
  })
  hls.on(Hls.Events.ERROR, function(event, data) {
    if (data.fatal) {
      switch (data.type) {
        case Hls.ErrorTypes.NETWORK_ERROR:
          // try to recover network error
          hls.startLoad()
          break
        case Hls.ErrorTypes.MEDIA_ERROR:
          hls.recoverMediaError()
          break
        default:
          // cannot recover
          hls.destroy()
          dispatch(destroyPlayer())
          break
      }
    }
  })
  video.addEventListener('ended', () => {
    dispatch(destroyPlayer())
  })
}

function createPlayerRequest() {
  return {
    type: CREATE_PLAYER
  }
}

export function playAndPause(player, playing, position, actionType) {
  return dispatch => {
    dispatch(updateProgress(position))
    if (actionType !== 'playAndPause') {
      player.currentTime = (duration / 100000) * position
      dispatch(setPlayerActionType('playAndPause'))
    }
    const isPlaying =
      video.currentTime > 0 &&
      !video.paused &&
      !video.ended &&
      video.readyState > 2

    if (!isPlaying) {
      setTimeout(() => {
        video.play()
      }, 150)
    } else {
      video.pause()
    }

    return dispatch(playPauseRequest(!playing))
  }
}

function playPauseRequest(playing) {
  return {
    type: PLAY_AND_PAUSE,
    payload: playing
  }
}

const progressInterval = setInterval(progress, 1000)

// Following code is a hack fix for Android OS to counteract a play/pause event triggered by the rc enter button
const checkPause = setInterval(isPausedCheck, 200)

function isPausedCheck() {
  if (!!store.getState().player) {
    if (!video.paused && !store.getState().player.playing) {
      video.pause()
    }
    if (video.paused && store.getState().player.playing) {
      video.play()
    }
  }
}

function progress() {
  if (!!store.getState().player) {
    const progress =
      (100000 / duration) * store.getState().player.element.currentTime > 100
        ? 100
        : (100000 / duration) * store.getState().player.element.currentTime
    progress > store.getState().player.progress &&
      store.getState().player.playing &&
      store.dispatch(updateProgress(progress))
  }
}

const spoolingInterval = setInterval(spooling, 1000)

function spooling() {
  if (!!video && !!store.getState().player.spooling) {
    let progress = (100000 / duration) * video.currentTime
    i++
    const p =
      store.getState().player.spooling === 'forward'
        ? progress + i * 2
        : progress - i * 2
    return p < 0
      ? store.dispatch(playAndPause(video, false, 0))
      : p < 100
      ? store.dispatch(updateProgress(p))
      : store.dispatch(destroyPlayer())
  }
}

function createPlayerReceived(payload) {
  return {
    type: PLAYER_READY,
    payload
  }
}

function paused() {
  return {
    type: PAUSED
  }
}

export function destroyPlayer() {
  return dispatch => {
    dispatch(removePlayer())
    if (!isEmpty(videoJSPlayer)) {
      videoJSPlayer.dispose()
      videoJSPlayer = null
    } else if (!isEmpty(hls)) {
      hls.destroy()
      hls = null
    } else {
      video.pause()
      video.src = ''
      video.remove()
    }
    i = 0
    playerContainer.classList.remove('display')
    // Force a click to restore keyboard / remote navigation
    document.getElementById('root').lastChild.click()
  }
}

export function fastForward(player, playing, position) {
  i = 0
  player.pause()
  return dispatch => {
    dispatch(spoolingPlayer('forward'))
    dispatch(setPlayerActionType('forward'))
  }
}

export function rewind(player, playing, position) {
  i = 0
  player.pause()
  return dispatch => {
    dispatch(spoolingPlayer('backward'))
    dispatch(setPlayerActionType('backward'))
  }
}

function spoolingPlayer(direction) {
  return {
    type: SPOOLING_PLAYER,
    payload: direction
  }
}

function updateProgress(payload) {
  return {
    type: UPDATE_PROGRESS,
    payload
  }
}

function removePlayer() {
  return {
    type: DESTROY_PLAYER
  }
}

function setPlayerActionType(actionType) {
  return {
    type: SET_PLAYER_ACTION_TYPE,
    payload: actionType
  }
}
