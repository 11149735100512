import { history } from 'store'

export const ADD_REDIRECT = 'ADD_REDIRECT'
export const USE_REDIRECT = 'USE_REDIRECT'

export const addRedirect = location => dispatch => {
  dispatch(add(location))
  history.push('/')
}
export const clearRedirect = location => dispatch => {
  dispatch(use())
}

const add = payload => ({ type: ADD_REDIRECT, payload })
const use = () => ({ type: USE_REDIRECT })
