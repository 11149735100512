export {
  baseRequest,
  getAuthHeaders,
  getBrightcoveAuthHeaders,
  getKey,
  notEqual,
  getEntityById,
  userHasSubscription,
  userHasActiveSubscription,
  downloadHls,
  isVirginTivoDevice,
  isVirginHorizonDevice,
  getVirginHorizonChannelId,
  addScript,
  addCss,
  getStandalonePlayerBaseUrl
} from './utils'
