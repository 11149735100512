import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Debug as DebugStyled } from './Debug.style'

class Debug extends Component {
  state = {
    visible: true,
    viewport: {
      width: 0,
      height: 0
    }
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.getViewportDimensions())
    this.getViewportDimensions()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.getViewportDimensions())
  }

  getViewportDimensions() {
    const { innerWidth, innerHeight } = window
    const { width, height } = this.state
    return (
      (width !== innerWidth || height !== innerHeight) &&
      this.setState({ viewport: { width: innerWidth, height: innerHeight } })
    )
  }

  render() {
    const { visible, viewport } = this.state
    const { width, height } = viewport
    const {
      REACT_APP_BUILD,
      REACT_APP_VERSION,
      REACT_APP_COMMIT_HASH
    } = process.env
    const { user } = this.props
    return (
      visible && (
        <DebugStyled>
          <span onClick={() => this.setState({ visible: false })}>X</span>
          <p>
            HOPSTER TV-CLIENT
            <br />
            w: {Math.round(width + width / 10)}px h:{Math.round(height)}px
            <br />
            build: {REACT_APP_BUILD}
            <br />
            version: {REACT_APP_VERSION}
            <br />
            user: {!!user ? <span>{user.customer_status}</span> : 'none'}
            <br />
            commit hash: {REACT_APP_COMMIT_HASH}
          </p>
        </DebugStyled>
      )
    )
  }
}

const mapStateToProps = ({ user }) => ({ user })
const connected = connect(mapStateToProps, {})(Debug)

export default connected
