import { ADD_REDIRECT, USE_REDIRECT } from './actions'

const initialState = {
  location: false
}

const reducer = function redirectReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_REDIRECT:
      return {
        location: action.payload
      }
    case USE_REDIRECT:
      return {
        location: false
      }
    default:
      return state
  }
}

export default reducer
