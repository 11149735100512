import React, { Fragment, Suspense } from 'react'
import { Routes, AppRoutes } from './AppRoutes'
import { FallbackDiv } from 'components/SignUp/SignUp.style'
import Debug from 'components/Debug'
import Player from 'components/Player'
import { getKey } from 'utils'

const AppView = () => (
  <Suspense fallback={<FallbackDiv>Loading...</FallbackDiv>}>
    {process.env.REACT_APP_COMMIT_HASH && <Debug />}
    <Player />
    {Routes().map(route => (
      <AppRoutes key={getKey()} {...route} />
    ))}
  </Suspense>
)

export default AppView
