import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchContent } from 'App/content/actions'
import { fetchFreeVideos, fetchCarouselVideos } from 'App/videos/actions'
import { usedKey } from 'App/focus/actions'
import { loginFromTokenRequest } from 'App/auth/actions'
import { history } from 'store'
import { destroyPlayer } from 'components/Player/actions'
import AppView from './AppView'
import {
  isVirginHorizonDevice,
  getVirginHorizonChannelId,
  addScript,
  addCss,
  getStandalonePlayerBaseUrl
} from 'utils'
import { VIRGIN_TIVO, ENVIRONMENT } from 'constant'

class App extends Component {
  state = {
    loadApp: false
  }

  UNSAFE_componentWillMount() {
    const { loginFromTokenRequest } = this.props
    loginFromTokenRequest()
    let n = window.location.search.substr(1)
    !!n && history.push(`show/${n.replace('hid=', '')}`)
  }

  componentDidMount() {
    const { REACT_APP_BUILD_TYPE, REACT_APP_ENVIRONMENT } = process.env
    if (
      REACT_APP_BUILD_TYPE === 'virgin' &&
      !document.referrer.includes(getStandalonePlayerBaseUrl())
    ) {
      try {
        if (isVirginHorizonDevice()) {
          this.virginHorizonEntitlementCheck()
        } else {
          // Code for Virgin-TiVo Device
          addCss('/codelibA/assets/codelibA.css')
          if (
            REACT_APP_ENVIRONMENT === ENVIRONMENT.DEVELOPMENT ||
            window.location.hostname === 'localhost'
          ) {
            addScript('/devCodelibA/codelibA.js', () => {
              this.virginTivoEntitlementCheck()
            })
          } else {
            addScript('/codelibA/codelibA.js', () => {
              this.virginTivoEntitlementCheck()
            })
          }
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      this.setState({ loadApp: true })
    }
    const { fetchContent, fetchFreeVideos, fetchCarouselVideos } = this.props
    fetchContent()
    fetchFreeVideos()
    fetchCarouselVideos()
    document.addEventListener('backbutton', this.onBackKeyDown, false)
  }

  componentWillUnmount() {
    document.removeEventListener('backbutton', this.onBackKeyDown)
  }

  virginHorizonEntitlementCheck = () => {
    window.onesdk.init()
    let app = this
    let channelId = getVirginHorizonChannelId()
    window.onesdk.isPackageEntitled(channelId).then(
      function(packageEntitled) {
        app.setState({ loadApp: true })
        return packageEntitled && packageEntitled.entitled
          ? history.push('/home')
          : history.push('/entitlement')
      },
      error => {
        console.error(error)
      }
    )
  }

  virginTivoEntitlementCheck = () => {
    window.vmDtvLib.lib.init(error => {
      if (!error) {
        try {
          window.vmDtvLib.customer.isPackageEntitled(
            VIRGIN_TIVO.LIVE_KIDS_PACK,
            result => {
              this.setState({ loadApp: true })
              return result
                ? history.push('/home')
                : history.push('/entitlement')
            }
          )
        } catch (error) {
          console.error(error)
        }
      }
    })
  }

  onAndroidBackPress = () => {}

  onBackKeyDown = e => {
    e.preventDefault()
    const { usedKey } = this.props
    usedKey('back')
  }

  render() {
    const {
      content: { contentReady },
      videos: { freeVideosReady, carouselsReady }
    } = this.props
    const { loadApp } = this.state
    return (
      contentReady &&
      freeVideosReady &&
      carouselsReady &&
      loadApp && <AppView />
    )
  }
}

const mapStateToProps = ({ content, videos, user }) => ({
  content,
  videos,
  user
})

const connected = connect(mapStateToProps, {
  fetchContent,
  fetchFreeVideos,
  fetchCarouselVideos,
  loginFromTokenRequest,
  destroyPlayer,
  usedKey
})(App)

export default connected
